import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import ShoppingLegal from './pages/ShoppingLegal';

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/shop" element={<Home />} />
        <Route exact path="/legal" element={<ShoppingLegal />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;