const SocialItem = ({platform, customUrl}) => {
  return (
    <li className="footer__social__item">
      { customUrl ? (
        <a href={customUrl} target="_blank" rel="noreferrer"><i className={`fab fa-${platform}`}></i></a>
        ) : (
          <a href={`https://${platform}.com/happyhauntingrecords`} target="_blank" rel="noreferrer" ><i className={`fab fa-${platform}`}></i></a>
        )
      }
    </li>
  )
}

export default SocialItem;