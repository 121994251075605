import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import ShoppingLegal from './pages/ShoppingLegal';

const App = () => {
  const SHOP_ENABLED = process.env.REACT_APP_SHOP_ENABLED;
  
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
        <Route exact path="/" element={<Home />} />
        {
          SHOP_ENABLED && (
            <>
            <Route exact path="/shop" element={<Home />} />
            <Route exact path="/legal" element={<ShoppingLegal />} />
            </>
          )
        }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;