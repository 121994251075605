import { useContext } from 'react';
import ProductDetails from './ProductDetails';
import { ShopContext } from '../contexts/ShopContext';
import { Parallax } from 'react-scroll-parallax';

const Shop = () => {
  const shopContext = useContext(ShopContext);
  const { products, handleAddToCart } = shopContext;

  return (
    <div className="shop">
      <Parallax speed={20}>
     <h2 className="shop__title title">Shop</h2>
       <div className="shop__products">
        {products.map(product => (
          <div className="product" key={product.id}>
            <ProductDetails product={product} handleAddToCart={handleAddToCart} />
          </div>
        ))}
        <div className="shop__products__legal">
          <p>*Preorder items are items that are purchased prior to their production. A preorder means you have reserved that item and it will be shipped to you once it is complete. Funds will deduct from your account at the time of purchase. A standard preorder timeline is anywhere between 2-12 weeks. <a href="/legal" target="_blank" rel="noreferrer">View Full Terms Here</a></p>
        </div>
      </div>
      <div className="shop__background"></div>
      </Parallax>
    </div>
  );
}

export default Shop;