import { useState, useRef, useEffect } from 'react';

const ProductDetails = ({ product, handleAddToCart }) => {
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);
  const [ctaText, setCtaText] = useState(null);
  const [imageHovered, setImageHovered] = useState(false);
  const img1 = useRef(null);
  const img2 = useRef(null);

  const toggleImages = () => {
    if(product.images.length > 1) {
      img1.current?.classList.toggle('active');
      img2.current?.classList.toggle('active');
    }
  }

  useEffect(() => {
    getCta();

    const rotateImagesInterval = setInterval(() => {
      rotateImages();
    }, 2500);

    return () => {
      clearInterval(rotateImagesInterval);
    }
  }, [product, imageHovered]);

  const getCta = () => {
    if(!product.availableForSale){
      setCtaText('Sold Out');
    }
    if(product.description.toLowerCase().includes('pre-order')) {
      setCtaText('Pre-Order');
    } else {
      setCtaText('Add to Cart');
    }
  }

  const rotateImages = () => {
    if(product.images.length > 1 && !imageHovered) {
      img1.current?.classList.toggle('active');
      img2.current?.classList.toggle('active');
    }
  }

  const addedToCart = (item) => {
    setCtaText('Added to Cart!');
    handleAddToCart(item);

    setTimeout(() => {
      getCta();
    }, 2000);
  }

  return (
    <div className="product-details">
       <h3 className="title">{product.title}</h3>
       <p className="price">${Number(selectedVariant.price.amount).toFixed(2)}</p>
       <div className="product-details__images" onMouseEnter={toggleImages} onMouseLeave={toggleImages}>
        <div className="product-details__images__image" onMouseEnter={() => setImageHovered(true)}>
          <div className="enlarge"><a href={product.images[0].src} target="_blank"><i className="fa-solid fa-magnifying-glass"></i></a></div>
          <img className="active" ref={img1} src={product.images[0].src} alt={product.title} />
          { product.images[1] && <img ref={img2} src={product.images[1]?.src} alt={product.title} /> }
        </div>
       </div>
       <p className="description">{product.description}</p>
        { product.variants.length > 1 && product.availableForSale ? <select className="size-selection" onChange={(e) => { setSelectedVariant(product.variants.find(v => v.id === e.target.value))}}>
          { product.variants.map(variant => (
            <option value={variant.id} key={variant.id}>
              {variant.title}
            </option>
          ))}
        </select> : null }
        {
          product.availableForSale ? (
            <button className={`add-to-cart ${ ctaText === 'Added to Cart!' ? 'orange' : 'red' }`} onClick={() => addedToCart(selectedVariant.id)}> { ctaText }</button>
          ) : (
            <button className="add-to-cart disabled" disabled>{ ctaText }</button>
          )
        }
    </div>
  )
}

export default ProductDetails;