import { useContext, useEffect, useState } from 'react';
import SocialItem from './SocialItem';
import { ContentfulContext } from '../contexts/ContentfulContext';

const Footer = () => {
  const contentfulContext = useContext(ContentfulContext);
  const [discordInvite, setDiscordInvite] = useState(null);

  useEffect(() => {
    contentfulContext.getData('3RqMUsQ1gIQIjAFWralaZ6')
    .then((entry) => setDiscordInvite(entry.fields?.discordInviteLink))
  }, []);

  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__social">
          <ul className="footer__social__list">
            <SocialItem platform="instagram" />
            <SocialItem platform="facebook" />
            <SocialItem platform="tiktok" customUrl={"https://tiktok.com/@happyhauntingrecords"} />
            <SocialItem platform="twitter" customUrl={"https://twitter.com/happyxhaunting"} />
            { discordInvite ? <SocialItem platform="discord" customUrl={discordInvite} /> : null }
          </ul>
        <div className="footer__email"><a href="mailto:steve@happyhauntingrecords.com">steve@happyhauntingrecords.com</a></div>
        </div>
        <div className="footer__legal">
          <p>&copy; 2023 Happy Haunting Entertainment LLC.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;