import { useEffect, useRef } from 'react';
import { useContext, useState } from 'react';
import { AppContext } from '../contexts/AppContext';
import { ContentfulContext } from '../contexts/ContentfulContext';
import NewsItem from './NewsItem';
import { Parallax } from 'react-scroll-parallax';
import Flickity from 'flickity';

const News = () => {
  const appContext = useContext(AppContext);
  const { device } = appContext;
  const [newsItems, setNewsItems] = useState([]);
  const [carouselDragged, setCarouselDragged] = useState(false);
  const contentfulContext = useContext(ContentfulContext);
  const listRef = useRef(null);
  const flktyOptions = {
    adaptiveHeight: true,
    draggable: device === 'mobile' ? true : false,
    prevNextButtons: true,
    wrapAround: false,
    autoPlay: false,
    pageDots: false,
    accessibility: false,
  }

  useEffect(() => {
    contentfulContext.getData('3RqMUsQ1gIQIjAFWralaZ6')
    .then((entry) => setNewsItems(entry.fields?.newsItems))
  }, [contentfulContext]);

  useEffect(() => {
    const flkty = new Flickity('.news__list', flktyOptions);
    flkty.on('dragStart', () => {
      setCarouselDragged(true);
    });
    flkty.on('scroll', () => {
      setCarouselDragged(true);
    });

    return () => {
      flkty.destroy();
    }
  }, [newsItems]);

  useEffect(() => {
    if(!carouselDragged) {
      const addLoop = setInterval(() => {
        listRef.current.classList.remove('bounce-loop');

        setTimeout(() => {
          listRef.current.classList.add('bounce-loop');
        }, 1000);
      }, 4000);

      return () => {
        clearInterval(addLoop);
      }
    }
  }, [carouselDragged]);

  const clickUrl = (url) => {
    console.log(url)
    window.open(url, '_blank');
  }

  return (
    <div className="news">
      <Parallax speed={0}>
      <h2 className="news__title title">News</h2>
      <div className="news__list" ref={listRef}>
        {newsItems.map((item, index) => (
           item.fields && <NewsItem key={index} item={item} />
        ))
        }
      </div>
      <div className="news__background"></div>
      </Parallax>
    </div>
  )
}

export default News;