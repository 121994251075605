import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';

const Cookies = () => {
  const appContext = useContext(AppContext);
  const { setCookies, setShowCookies, showCookies } = appContext;

  return (
    <div className="cookies" style={{'display': showCookies ? 'block' : 'none'}}>
      <div className="cookies__content">
        <p className="cookies__copy">We use cookies to improve your experience on our site.<br></br>By using our site you consent cookies.</p>
        <div className="cookies__buttons">
          <button onClick={() => setCookies(false)}>Decline</button> 
          <button onClick={() => setCookies(true)}>Accept</button>
          <div className="close-btn" onClick={() => setShowCookies(false)}><i className="fa fa-close"></i></div>
        </div>
      </div>
    </div>
  );
}

export default Cookies;