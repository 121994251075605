import { createContext } from 'react';
const contentful = require('contentful');

const ContentfulContext = createContext();
const ContentfulProvider = ({ children }) => {
  const env = process.env.REACT_APP_ENV || 'development';
  const client = contentful.createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    environment: 'master', // defaults to 'master' if not set
    accessToken: env ===  'production' ? process.env.REACT_APP_CONTENTFUL_ACCESSTOKEN : process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN,
    host: env ===  'production' ? 'cdn.contentful.com' : 'preview.contentful.com'
  })

  const getData = async (entryId) => {
    try {
      const entry = await client.getEntry(entryId)
      return entry;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <ContentfulContext.Provider value={{getData}}>
      {children}
    </ContentfulContext.Provider>
  );
}
const ContentfulConsumer = ContentfulContext.Consumer;
export { ContentfulContext, ContentfulProvider, ContentfulConsumer };