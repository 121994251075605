import { useContext } from 'react';
import { ParallaxProvider } from "react-scroll-parallax";
import Carousel from '../components/Carousel';
import Cookies from '../components/Cookies';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import News from '../components/News';
import Shop from '../components/Shop';
import Videos from '../components/Videos';
import { AppContext } from '../contexts/AppContext';
import { ContentfulProvider } from '../contexts/ContentfulContext';
import { ShopProvider } from '../contexts/ShopContext';

const Home = () => {
  const appContext = useContext(AppContext);
  const { cookiesAccepted } = appContext;
 
  return (
    <div className="home">
      {
        cookiesAccepted ? null : <Cookies />
      }
      <ShopProvider>
        <Nav />
        <div className="app__content">
        <ContentfulProvider>
        <ParallaxProvider>
          <Carousel />
          <Shop />
          <Videos />
          <News />
        </ParallaxProvider>
        </ContentfulProvider>
        </div>
      </ShopProvider>
      <ContentfulProvider>
        <Footer />
      </ContentfulProvider>
    </div>
  );
}

export default Home;