import { useRef, useEffect, useState } from 'react';

const DownArrow = () => {
  const containerRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY >= 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="arrow-container" ref={containerRef}>
      <div className="skull"><img src={require('../images/skull_arrow.png')} alt="" /></div>
      <div className="down-arrow">
        <img src={require('../images/bone_arrow.png')} alt="" />
      </div>
    </div>
  )
}

export default DownArrow;