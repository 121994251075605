import { createContext, useEffect, useState } from 'react';
import Client from 'shopify-buy';

const ShopContext = createContext();
const ShopProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [checkout, setCheckout] = useState(null);
  const [products, setProducts] = useState([]);
  
  const client = Client.buildClient({
    domain: process.env.REACT_APP_SHOPIFY_DOMAIN,
    storefrontAccessToken: process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN
  });
  const collectionId = process.env.REACT_APP_SHOPIFY_COLLECTION;

  useEffect(() => {
    try {
      client.collection.fetchAllWithProducts(collectionId).then((collections) => {
        setProducts(collections[0].products);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const initializeCheckout = async () => {
    try {
      const newCheckout = await client.checkout.create();
      setCheckout(newCheckout);
    } catch (error) {
      console.log(error);
    }
  }

  const handleAddToCart = async (variantId) => {
    const lineItemsToAdd = [
      {
        variantId,
        quantity: 1
      }
    ];
    try {
      setCart(await client.checkout.addLineItems(checkout.id, lineItemsToAdd));
    } catch (error) {
      console.log(error);
    }
  };

  const clearCart = async () => {
    try {
      setCart([]);
      setCheckout(null);
      initializeCheckout();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if(cart.lineItems?.length > 0) {
      localStorage.setItem('cart', JSON.stringify(cart));
      localStorage.setItem('checkout', JSON.stringify(checkout));
    }
  }, [cart]);

  useEffect(() => {
    if(localStorage.getItem('cart')) {
      setCart(JSON.parse(localStorage.getItem('cart')));
      setCheckout(JSON.parse(localStorage.getItem('checkout')));
    } else {
      initializeCheckout();
    }
  }, []);

  return (
    <ShopContext.Provider value={{ cart, setCart, products, setProducts, checkout, setCheckout, handleAddToCart, clearCart }}>
      {children}
    </ShopContext.Provider>
  );
}

const ShopConsumer = ShopContext.Consumer;
export { ShopContext, ShopProvider, ShopConsumer };
