import { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '../contexts/AppContext';
import { ContentfulContext } from '../contexts/ContentfulContext';
import CarouselItem from './CarouselItem';
import DownArrow from './DownArrow';

const Carousel = () => {
  const appContext = useContext(AppContext);
  const { device } = appContext;
  const [desktopItems, setDesktopItems] = useState([]);
  const [mobileItems, setMobileItems] = useState([]);
  const autoPlay = true;
  const [carouselHeight, setCarouselHeight] = useState(window.innerHeight);
  const contentfulContext = useContext(ContentfulContext);
  const carouselSpeed = 5;
  const [currentItem, setCurrentItem] = useState(0);
  const itemRef = useRef(null);

  const resizeHandler = () => {
    if(window.innerWidth < 768) {
      if(device !== 'mobile') {
        setCurrentItem(0);
      }
    } else if(window.innerWidth < 1024) {
      if(device !== 'tablet') {
        setCurrentItem(0);
      }
    } else {
      if(device !== 'desktop') {
        setCurrentItem(0);
      }
    }
    setCarouselHeight(window.innerHeight);
  }

  useEffect(() => {
    const getContentful = async () => {
      try {
        const entry = await contentfulContext.getData('3RqMUsQ1gIQIjAFWralaZ6');
  
        entry.fields.galleryItems.forEach(item => {
          if(item.fields.media) {
            setDesktopItems(prevState => [...prevState, {
              copy: item.fields.copy,
              copyColor: item.fields.copyColor,
              fields: item.fields.media.fields,
              url: item.fields.url,
              ctaText: item.fields.ctaText
            }]);
          }
        });
  
        entry.fields.galleryItems.forEach(item => {
          if(item.fields.mobileMedia) {
            setMobileItems(prevState => [...prevState, {
              copy: item.fields.copy,
              copyColor: item.fields.copyColor,
              fields: item.fields.mobileMedia.fields,
              url: item.fields.url,
              ctaText: item.fields.ctaText
            }]);
          }
        });
  
      } catch (error) {
        console.log(error);
      }
    };
    
    getContentful();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, []);

  const changeItem = () => {
    itemRef.current.classList.remove('fadeIn');
    itemRef.current.classList.add('fadeOut');
    const itemsLength = device === 'mobile' ? mobileItems.length : desktopItems.length;
    const nextItemTimeout = setTimeout(() => {
      if (currentItem === itemsLength - 1) {
        setCurrentItem(0);
      } else {
        setCurrentItem(currentItem + 1);
      }
      itemRef.current.classList.remove('fadeOut');
    }, 1000);
    const emptyTimeout = setTimeout(() => {
      itemRef.innerHTML = '';
      itemRef.current.classList.add('fadeIn');
    }, 1100);
  
    return () => {
      clearTimeout(nextItemTimeout);
      clearTimeout(emptyTimeout);
    };
  };
  
  useEffect(() => {
    const carouselInterval = autoPlay && setInterval(changeItem, carouselSpeed * 1000);
  
    return () => {
      clearInterval(carouselInterval);
    };
  }, [desktopItems, mobileItems, carouselSpeed, currentItem]);

  const loadCarouselItem = () => {
    const item = device === 'mobile' ? mobileItems[currentItem] : desktopItems[currentItem];

    return (
      <CarouselItem copy={item?.copy} copyColor={item?.copyColor} url={item?.url} ctaText={item?.ctaText} item={item.fields}/>
    );
  }

  return (
    <div className="carousel" style={{ height: carouselHeight }}>
        <div className="carousel__items">
          <div className="item-container fadeIn" ref={itemRef}>
            { desktopItems.length > 0 && loadCarouselItem() }
          </div>
        </div>
      <DownArrow />
      <div className="carousel__hands"></div>
    </div>
  )
}

export default Carousel;