import { useState, useRef, useEffect } from "react"

const CarouselItem = ({item, copy, copyColor, ctaText, url}) => {
  const itemRef = useRef(null);
  const displayCarouselItem = () => {
    if(item.file.contentType === 'video/mp4') {
        return (
          <video autoPlay muted playsInline loop src={item.file.url} />
        )
    } else {
      return (
        <img src={item.file.url} alt={item?.title} />
      )
    }
  }
  
  return (
    <div className={`carousel__item`} ref={itemRef}>
      {
        copy && (
          <div className="carousel__item__copy">
              <span className={`${ copyColor.toLowerCase().replaceAll(' ','-') }`}>{copy}</span>
              <div className="carousel__item__copy__cta"><span className={`${ copyColor.toLowerCase().replaceAll(' ','-') }`}><a href={url} target="_blank" rel="noreferrer">{ctaText}</a></span></div>
          </div>
        )
      }
      { displayCarouselItem() }
    </div>
  )
}

export default CarouselItem;