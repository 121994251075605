const ShoppingLegal = () => {
  return (
    <div className="legal">
      <p>Orders placed are no longer able to be cancelled or edited in any way. Please make sure to review your cart before placing any orders. Orders will be shipped according to the associated fulfillment/pre-order timeline.</p>
      <p>We ship worldwide! Some International orders can take up to 4-6 weeks to arrive, so please be patient. Your order may be subject to import duties and taxes (including VAT), which are incurred once a shipment reaches your destination country.</p>
      <p>Happy Haunting Records is not responsible for these charges if they are applied and they are your responsibility as the customer. Please contact your local post office or customs office for exact rates.</p>
      <p>All orders will be charged at the time of purchase</p>
      <p>All shipping costs will be displayed at checkout. Costs depend on where you live and what items you order. The costs and methods that are provided at checkout come directly from the postal courier themselves and are not in our control.</p>
      <p>Please note that the purchase of expedited shipping is for faster delivery by the postal courier once shipped and does not affect fulfillment time in any way. The funds go directly to the courier and it is the courier's responsibility to honor the expedited shipping as printed on the label we affix to the package.We are not able to provide refunds for shipping on behalf of the courier should they not honor the timeline according to the postage paid.</p>
      <p>When you check out with an item on "pre-order" in your cart, the entire order will ship as one cohesive package once the pre-order arrives. If you want an item ready to ship immediately, it is important that you place a separate order from any other items on pre-order.</p>
      <p>Unfortunately we cannot issue replacements for cosmetic damages from transit as we are not provided with extra sleeves for vinyl from the manufacturer.</p>
      <p>Please note that we cannot be held liable for, and do not issue refunds or replacements for, minor cosmetic damages suffered in transit. This includes but is not limited to: vinyl with dinged corners, seam splits, or bent jackets. With this in mind, please use caution with USPS Media Mail! If you are a stickler for condition or are worried about your items suffering any cosmetic damage, please consider upgrading your shipping service to UPS Ground.</p>
      <p>Once the package leaves our facility, it is out of our hands. We cannot be held responsible for lost or stolen packages.</p>
      <p>All return/exchange requests must be made within 30 days of the shipment delivery date. Items must be in original/unwashed/unworn condition to be eligible for a refund.</p>
      <p>Unless it is being returned due to a production defect, we can not offer a refund for your original shipping costs. We are not responsible for exchange/return shipping costs incurred. All returns will be subject to a $3 restocking fee.</p>
      <p>Please EMAIL <a href="mailto:steve@happyhauntingrecords.com">steve@happyhauntingrecords.com</a> for any returns or exchanges BEFORE sending your order back to us.</p>
    </div>
  )
}
export default ShoppingLegal;