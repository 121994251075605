import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';

const NewsItem = ({item}) => {
  const dateString = item.sys.createdAt;
  const date = new Date(dateString);
  const dateOptions = { month: '2-digit', year: 'numeric' };
  const formattedDate = date.toLocaleString('en-US', dateOptions);
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p>{children}</p>
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h1>{children}</h1>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3>{children}</h3>
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return <h4>{children}</h4>
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return <h5>{children}</h5>
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        return <h6>{children}</h6>
      },
      [MARKS.BOLD]: (node, children) => {
        return <span className="bold">{children}</span>
      },
      [MARKS.ITALIC]: (node, children) => {
        return <span className="italic">{children}</span>
      },
      [MARKS.UNDERLINE]: (node, children) => {
        return <span className="underline">{children}</span>
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return <a href={node.data.uri} target="_blank" rel="noreferrer">{children}</a>
      },
    }
  }
  return (
    <div className="news__list__item">
      {
        item.fields.image && <div className="news__list__item__image"><img src={item.fields.image.fields.file.url} alt={item.fields.image.fields.title} /></div>
      }
      <div className={`news__list__item__text ${!item.fields.image && 'no-image'}`}>
        <p className="news__list__item__text__date">{formattedDate}</p>
        { item.fields.title && <h3 className="news__list__item__text__title">{item.fields.title}</h3> }
        { item.fields.copy && <div className="news__list__item__text__copy">{documentToReactComponents(item.fields.copy, options)}</div> }
      </div>
    </div>
  )
}

export default NewsItem;