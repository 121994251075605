import React, { createContext, useEffect, useState } from 'react';

const AppContext = createContext();
const AppProvider = ({ children }) => {
  const [cartActive, setCartActive] = useState(false);
  const [device, setDevice] = useState(window.innerWidth < 768 ? 'mobile' : window.innerWidth < 1024 ? 'tablet' : 'desktop');
  const [showCookies, setShowCookies] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  
  useEffect(() => {
    const pageSection = window.location.pathname.replace('/', '');
    const urlParams = new URLSearchParams(window.location.search);

    if(pageSection) {
      const element = document.getElementsByClassName(`${pageSection}`)[0];

      if(element) {
        const scrollTimeout = setTimeout(() => {
          if(window.innerWidth < 768) {
            window.scrollTo(0, element.offsetTop - 100);
          } else {
            window.scrollTo(0, element.offsetTop);
          } 
        }, 500);

        return () => {
          clearTimeout(scrollTimeout);
        }
      }
    }

  },[]);

  useEffect(() => {
    const cookies = localStorage.getItem('cookies');

    if(cookies === "true") {
      setCookiesAccepted(true);
      setAnalytics();
    } if(cookies === "false") {
      setCookiesAccepted(false);
      setShowCookies(false);
    } else {
      setShowCookies(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, [device]);

  const resizeHandler = () => {
    if(window.innerWidth < 768) {
      if(device !== 'mobile') {
        // window.location.reload();
      }
      setDevice('mobile');
    } else if(window.innerWidth < 1024) {
      if(device !== 'tablet') {
        // window.location.reload();
      }
      setDevice('tablet');
    } else {
      if(device !== 'desktop') {
        // window.location.reload();
      }
      setDevice('desktop');
    }
  }

  const setCookies = (consent) => {
    localStorage.setItem('cookies', consent);
    setShowCookies(false);
    setCookiesAccepted(consent);
    if(consent) {
      setAnalytics();
    }
  }

  const setAnalytics = () => {
    window.gtag('js', new Date());
    window.gtag('config', 'G-F0P14SGNP6');
  }
  
  
  return (
    <AppContext.Provider value={{ cookiesAccepted, device, setCookies, showCookies, setShowCookies }}>
      {children}
    </AppContext.Provider>
  );
}

const AppConsumer = AppContext.Consumer;
export { AppContext, AppProvider, AppConsumer };
