import { useState, useContext, useEffect, useRef } from 'react';
import { ShopContext } from '../contexts/ShopContext';
import logo from '../images/hh_horz_logo.svg';

const Nav = () => {
  const shopContext = useContext(ShopContext);
  const { cart, clearCart } = shopContext; 
  const [cartUrl, setCartUrl] = useState(null);
  const logoRef = useRef(null);
  const navRef  = useRef(null);
  const cartCount = useRef(null);
  const flickerTimeout = 10;

  useEffect(() => {
    const addLoop = setInterval(() => {
      logoRef.current.classList.remove('flicker-loop');

      setTimeout(() => {
        logoRef.current.classList.add('flicker-loop');
      }, 1000);
    }, flickerTimeout * 1000);

    return () => {
      clearInterval(addLoop);
    }
  }, []);

  useEffect(() => {
    cartCount.current?.classList.add('added');
    
    const removeClass = setTimeout(() => {
      cartCount.current?.classList.remove('added');
    } , 1300);

    if(cart.lineItems?.length > 0) {
      const cartItems = cart.lineItems.filter(item => item.variant.id).map(item => {
        const variantId = item.variant.id.replace('gid://shopify/ProductVariant/', '');
        const variantQuantity = item.quantity;
        return `${variantId}:${variantQuantity}`;
      });

      setCartUrl(`https://${process.env.REACT_APP_SHOPIFY_DOMAIN}/cart/${cartItems.join(',')}?storefront=true`);
    }

    return () => {
      clearTimeout(removeClass);
    }
  }, [cartCount, cart.lineItems]);

  useEffect(() => {
    const removeIntro = setTimeout(() => {
      logoRef.current.classList.remove('intro');
    }, 4000);

    return () => {
      clearTimeout(removeIntro);
    }
  }, []);

  const getCartTotal = () => {
    let total = 0;
    cart.lineItems?.forEach(item => {
      total += item.quantity;
    });
    return total;
  }

  const cartClick = () => {
    window.open(cartUrl);
    localStorage.removeItem('cart');
    localStorage.removeItem('checkout');
    clearCart();
  }

  const checkCart = () => {
    if (cart.lineItems?.length > 0) {
      return (
        <div className="nav__cart" onClick={cartClick}>
          <div className="nav__cart__count added" ref={cartCount}>{getCartTotal()}</div>
          <i className="fa-solid fa-shopping-cart"></i>
        </div>
      )
    }
  }

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  return (
    <div className="nav" ref={navRef}>
      <div className="nav__logo intro" ref={logoRef} onClick={scrollToTop}><img src={logo} 
      alt="Happy Haunting Records" /></div>
      { checkCart() }
      <div className="nav__drip">
        <img src={require('../images/drip_nav.svg').default} alt="drip" />
      </div>
      <div className="nav__background"></div>
    </div>
  )
}

export default Nav;