import { useEffect, useContext, useState } from 'react';
import { ContentfulContext } from '../contexts/ContentfulContext';
import { Parallax } from 'react-scroll-parallax';

const Videos = () => {
  const [videoItems, setVideoItems] = useState([]);
  const contentfulContext = useContext(ContentfulContext);

  useEffect(() => {
    contentfulContext.getData('3RqMUsQ1gIQIjAFWralaZ6')
    .then((entry) => setVideoItems(entry.fields?.videoItems))
  }, [contentfulContext]);

  const clickUrl = (url) => {
    window.open(url, '_blank');
  }

  return (
    <Parallax speed={-10}>
    <div className="videos">
      <h2 className="videos__title title">Videos</h2>
      {videoItems.map((item, index) => (
        <div className="videos__item" key={index} style={{backgroundImage: `url(${item.fields.thumbnail.fields.file.url}`}} onClick={item.fields.url ? () => clickUrl(item.fields.url) : null}>
          <h3 className="videos__item__title">{item.fields.copy}</h3>
        </div>
      ))
      }
    </div>
    </Parallax>
  )
}

export default Videos;